<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="dialog-deleteNode-activator">
        <slot></slot>
      </div>
    </template>

    <v-card v-if="waitingForUsersCount">
      <v-card-title class="primary d-flex justify-space-between">
        <span>
          {{$t('routes.node.deleteNode')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>


      <v-card-text class="pt-5 scrollable">
        <div v-if="errorMessage" class="error">
          {{ errorMessage }}
        </div>
        <div v-if="errorTranslatable" class="error">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>
        <div class="text-center" v-else>
          <v-progress-circular class="float-left"
          indeterminate
          color="primary">
          </v-progress-circular>
          <h3 class="pl-2 mt-2 float-left">{{$t('routes.node.loading')}}</h3>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" data-cy="dialog-deleteNode-false">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="!waitingForUsersCount" data-cy="dialog-deleteNode-modal">
      <v-card-title class="red darken-3 d-flex justify-space-between">
        <span>
          {{$t('routes.node.deleteNode')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-5 scrollable" data-cy="dialog-deleteNode-nodeName">
        <div>
        {{deleteMessage}}
        </div>

        <div>
          {{node.name}}
        </div>

        <div v-if="errorMessage" class="error">
          {{ errorMessage }}
        </div>
        <div v-if="errorTranslatable" class="error">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="deleteNode"
            elevation=0
            color="red darken-3"
            data-cy="dialog-deleteNode-ok"
            :disabled="deletionInProgress"
        >
          {{ $t('common.delete') }}
        </v-btn>
        <v-btn text @click="cancel" data-cy="dialog-deleteNode-false">{{$t('common.cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "DeleteNodeDialog",
  props: {
    node: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    return {
      dialog: false,
      waitingForUsersCount: true,
      userCount: 0,
      errorMessage: '',
      errorTranslatable: '',
      deletionInProgress: false,
    }
  },
  methods: {
    getRedirectToNodeId: function() {
      let redirectToNode = null;

      if (this.currentNode.id === this.node.id) {
        if (this.currentNode.parentid) {
          redirectToNode = this.currentNode.parentid;
        } else {
          const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
          redirectToNode = this.$store.getters[ROOTNODEID];
        }

        return redirectToNode;
      }

      const ids = this.currentNode.rootpath.split('/').filter(id => id !== '');

      if (ids.includes(this.node.id)) {
        if (this.node.parentid) {
          redirectToNode = this.node.parentid;
        } else {
          const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
          redirectToNode = this.$store.getters[ROOTNODEID];
        }
      }

      return redirectToNode;
    },
    deleteNode: function() {
      this.errorMessage = '';
      this.errorTranslatable = '';
      let self = this;

      this.deletionInProgress = true;

      const redirectToNodeId = this.getRedirectToNodeId(this.node);

      const DELETENODES = this.$store.getters.keywords.NODE.DELETENODES;
      this.$store.dispatch(DELETENODES, [this.node.id])
        .then(function() {
          if (redirectToNodeId !== null) {
            setTimeout(() => {
              self.$router.push({path: `/node/${redirectToNodeId}`});
            }, 500);
          }

          self.dialog = false;
          self.userCount = 0;
        })
        .catch(function(error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            const apiError = error.response.data.error.message;
            if (typeof apiError == 'object') {
              self.errorTranslatable = apiError;
            } else {
              self.errorMessage = apiError;
            }
          } else {
            console.log("Error deleting active node: ", error);
            self.errorMessage = self.$t('routes.node.deleteNodeDialogErrorDeletingActiveNode');
          }
        })
        .finally(function() {
          self.deletionInProgress = false;
        });
    },
    cancel: function() {
      this.dialog = false;
      this.waitingForUsersCount = false;
      this.userCount = 0;
    },
  },
  computed: {
    currentNode: function() {
      const CURRENTNODE = this.$store.getters.keywords.NODE.CURRENTNODE;
      return this.$store.getters[CURRENTNODE]
    },
    deleteMessage: function() {
      if (this.userCount === 0) {
        return this.$t('routes.node.deleteNodeDialogMessageNoUsers');
      } else {
        return this.$tc('routes.node.deleteNodeDialogMessageWithUsers', this.userCount);
      }
    }
  },
  watch: {
    dialog: function(newValue) {
      this.errorMessage = '';
      this.errorTranslatable = '';

      if (newValue === true) {
        this.deletionInProgress = false;
        this.waitingForUsersCount = true;
        let self = this;
        const GETUSERS = this.$store.getters.keywords.NODE.GETUSERS;
        this.$store.dispatch(GETUSERS, [this.node.id])
        .then(function(result) {
          self.userCount = result.enabled;
          self.waitingForUsersCount = false;
        })
        .catch(function(error) {
          console.log("Error getting user count: ", error);

          if (error && error.response && error.response.data && error.response.data.error) {
            const apiError = error.response.data.error.message;
            if (typeof apiError == 'object') {
              self.errorTranslatable = apiError;
            } else {
              self.errorMessage = apiError;
            }
          } else {
            self.errorMessage = self.$t('routes.node.deleteNodeDialogErrorDeletingActiveNode');
          }
        });
      }
    }
  },
}
</script>

<style>
</style>
