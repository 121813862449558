<template>
  <v-dialog
      v-model="displayDialog"
      persistent
      max-height="680"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
      style="z-index: 1100 !important;"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="confirm-customLabels-activator">
        <slot></slot>
      </div>
    </template>

    <v-card v-cloak data-cy="confirm-customLabels-modal">
      <v-card-title class="primary d-flex justify-space-between mb-2">
        {{ $t('routes.pod.menuEditLabels.predefinedLabels') }}

        <v-btn icon @click="displayDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="scrollable">
        <div class="labels-tree">
          <v-treeview
            :items="categories"
            v-model="selectedLabels"
            item-text="name"
            :label="$t('routes.pod.menuEditLabels.selectCategory')"
            selection-type="leaf"
            selectable return-object open-all
          />
        </div>

        <!-- Chips view for labels -->
        <div class="chips-container">
          <v-chip
            v-for="(label, index) in selectedLabels"
            :key="index"
            close
            @click:close="removeLabel(index)"
            class="ma-2"
            label
          >
            {{ label.name }}
          </v-chip>
        </div>

        <div v-if="cannotAddLabels" class="font-italic">
          {{ $t('routes.pod.menuEditLabels.noLabels') }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="cannotAddLabels || isProcessingLabels"
          class="ml-auto"
          color="primary"
          elevation=0
          @click="$emit('selected', selectedLabels.map((label) => label.name))"
          data-cy="dialog-customLabels-ok"
        >
          {{ $t('routes.pod.menuEditLabels.addLabels') }}
        </v-btn>
        <v-btn text @click="displayDialog = false" class="btnCancel" data-cy="dialog-customLabels-false">
          {{ $t('common.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogActionKeys from '@/components/mixin/DialogActionKeys';

export default {
  mixins: [DialogActionKeys],
  name: "SelectCustomLabelsDialog",
  props: {
    categories: {
      type: Array,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    isProcessingLabels: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['selected'],
  data: function() {
    return {
      selectedLabels: [],
      displayDialog: false,
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  watch: {
    displayDialog: function (val) {
      if (val) {
        this.selectedCategory = null;
        this.selectedLabels = [];
      }
    },
    shouldDisplayDialog: function (val) {
      if (!val) {
        // We only care for this flag, when dialog close is requested. Otherwise,
        // it's up to the activator to show it up.
        this.displayDialog = false;
      }
    },
  },
  methods: {
    removeLabel(index) {
      this.selectedLabels.splice(index, 1);
    },
    handleKeyDown(event) {
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.displayDialog = false;
      }
    }
  },
  computed: {
    accessPointId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    cannotAddLabels() {
      return this.selectedLabels.length === 0 || this.categories.length === 0;
    },
    shouldDisplayDialog() {
      return this.$props.isVisible;
    },
  },
}
</script>

<style scoped>
.labels-tree {
  padding: 1.2rem;
}
</style>
