// import { Amplify, PubSub } from 'aws-amplify';
// import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { PubSub } from '@aws-amplify/pubsub';

import Api from '../../api/Api'

let Subscription = null;
let pubsubClient = null;

const NONOTIFICATIONS = 0;
const YELLOWNOTIFICATIONS = 1;
const REDNOTIFICATIONS = 2;

export default {
  namespaced: true,
  state: {
    notifications: [],
    newNotification: { type: 0 }, // 0 - none, 1 - yellow, 2 - red
  },
  getters: {
    getNotifications: function(state) {
      return state.notifications;
    },
    newNotification: function(state) {
      return state.newNotification;
    },
  },
  mutations: {
    setNotifications: function(state, notifications) {
      state.notifications = notifications.sort(function (a, b) {
        let dateA = new Date(a.createdat);
        let dateB = new Date(b.createdat);
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1;
        return 0;
      });
    },
    setNewNotification: function(state, payload) {
      state.newNotification = {
        type: payload.type,
        tenantId: payload?.tenantId || null,
      };
    },

  },
  actions: {
    refreshNotifications: function(context, payload) {
      return Api.notification.getNotifications(payload)
      .then(function(result) {
        context.commit('setNotifications', result.data);
        context.commit('setNewNotification', { type: NONOTIFICATIONS });
        // console.log("Notifications", result);
      });
    },
    unsubscribe: function() {
      if (Subscription) {
        Subscription.unsubscribe();
      }
    },
    subscribe: function(context) {
      return Api.notification.getNotificationTopics()
      .then(function(result) {
        if (!pubsubClient) {
          pubsubClient = new PubSub(result.data.settings);
        }

        Subscription = pubsubClient.subscribe({ topics: result.data.topics }).subscribe({
            next: data => context.dispatch("onMessageReceived", data),
            error: error => console.error(error),
            close: () => console.log('Done'),
        });
      })
      .catch((err) => console.error(err));
    },
    onMessageReceived: function(context, data) {
      console.log("Got new notification", data);
      switch (data['Symbol(topic)']) {
        case 'broadcast': console.log("Broadcast received", data); break; // system broadcasted messages
        default:
          // If there is a link to the message, we will fetch the data from a server and use it as a message.
          if (data.responseDataLink) {
            fetch(data.responseDataLink)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response to get push notification message was not OK.');
                }

                return response.json();
              })
              .then(messageData => {
                context.dispatch('checkContext', messageData);
              })
              .catch(error => {
                console.error('Fetch error:', error);
              });
          } else {
            context.dispatch('checkContext', data);
          }
          break;
      }
    },
    checkContext: function(context, notification) {
      const REFRESHDBUSER = context.rootGetters.keywords.AUTH.REFRESHDBUSER;
      const ADDORUPDATEPOD = context.rootGetters.keywords.POD.ADDORUPDATEPOD;
      const UPDATEORIGNOREPOD = context.rootGetters.keywords.POD.UPDATEORIGNOREPOD;
      const UPDATESTATEMESSAGE = context.rootGetters.keywords.POD.UPDATESTATEMESSAGE;
      const UPDATELAUNCHEDSERVICES = context.rootGetters.keywords.POD.UPDATELAUNCHEDSERVICES;
      const ADDLAUNCHEDSERVICE = context.rootGetters.keywords.POD.ADDLAUNCHEDSERVICE;
      const REMOVELAUNCHEDSERVICES = context.rootGetters.keywords.POD.REMOVELAUNCHEDSERVICES;
      const REFRESHTRANSCRIPTLINES = context.rootGetters.keywords.POD.TRANSCRIPT.REFRESHTRANSCRIPTLINES;
      const REFRESHMLLABELS = context.rootGetters.keywords.POD.LABEL.REFRESHMLLABELS;
      const CREATESNACKBAR = context.rootGetters.keywords.MAIN.CREATESNACKBAR;
      const GETVIEWPOD = context.rootGetters.keywords.POD.GETVIEWPOD;
      const NODECREATEDNOTIFICATION = context.rootGetters.keywords.NODE.NODECREATEDNOTIFICATION;
      const NODEUPDATEDNOTIFICATION = context.rootGetters.keywords.NODE.NODEUPDATEDNOTIFICATION;
      const NODEDELETEDNOTIFICATION = context.rootGetters.keywords.NODE.NODEDELETEDNOTIFICATION;
      const NODEMOVEDNOTIFICATION = context.rootGetters.keywords.NODE.NODEMOVEDNOTIFICATION;
      const NODECOPIEDNOTIFICATION = context.rootGetters.keywords.NODE.NODECOPIEDNOTIFICATION;
      const PODMOVEDNOTIFICATION = context.rootGetters.keywords.POD.PODMOVEDNOTIFICATION;
      const PODCOPIEDNOTIFICATION = context.rootGetters.keywords.POD.PODCOPIEDNOTIFICATION;
      const PODCOPYJOBNOTIFICATION = context.rootGetters.keywords.POD.PODCOPYJOBNOTIFICATION;
      const PODDELETEDNOTIFICATION = context.rootGetters.keywords.POD.PODDELETEDNOTIFICATION;
      const USERDISABLEDNOTIFICATION = context.rootGetters.keywords.AUTH.USERDISABLEDNOTIFICATION;
      const PROMPTREFRESHCOMMENTS = context.rootGetters.keywords.POD.COMMENT.PROMPTREFRESHCOMMENTS;

      switch (notification.context) {

        case 'general-error': {
            const snack = {
              i18n: "common.generalError",
              color: "error",
            };

            if (notification.message && notification.message.code) {
              delete snack.i18n;
              snack.replaceTCode = notification.message.code;
              snack.replaceTParams = notification.message.params;
              snack.replaceTFallback = "common.generalError";
            }

            context.commit(CREATESNACKBAR, snack, {root: true});
          }
          break;

        case 'state-message-updated':
          context.dispatch(UPDATESTATEMESSAGE, notification, {root: true});
          break;

        case 'service-started':
          context.dispatch(ADDLAUNCHEDSERVICE, notification, {root: true});
          context.dispatch(UPDATESTATEMESSAGE, notification, {root: true});
          break;

        case 'update-launched-services':
          context.dispatch(UPDATELAUNCHEDSERVICES, notification, {root: true});
          break;

        case 'pod-updated':
          console.log("Notification: ", notification);
          if (notification.error) {

            if (notification.error == "NM27" && notification.message && notification.message.code) {
              const snack = {
                i18n: "common.serviceError",
                replaceTCode: notification.message.code,
                replaceTParams: notification.message.params,
                replaceTFallback: "common.serviceUnknown",
                color: "error",
              };
              context.commit(CREATESNACKBAR, snack, {root: true});

              // Clean the messages after 3 seconds, if it is latest one in the message queue.
              if (notification.queue && notification.queue === "end") {
                setTimeout(() => {
                  context.commit(REMOVELAUNCHEDSERVICES, notification, {root: true});
                }, 3000);
              }

              if (notification.pod) {
                context.dispatch(UPDATEORIGNOREPOD, notification.pod, {root: true});
              }
              return;
            }

            console.log("Unexpected error occured: ", notification);
            return;
          }

          context.dispatch(ADDORUPDATEPOD, notification.pod, {root: true});

          if (notification.message) {
            context.commit('setNewNotification', { type: YELLOWNOTIFICATIONS });
          }

          // Refresh transcript lines
          if (notification.code && notification.code == "AM17" && notification.message && notification.message.code && notification.message.code   == "NM26") {
            const viewPod = context.rootGetters[GETVIEWPOD];
            if (viewPod && viewPod.id && viewPod.id == notification.pod.id) {
              context.dispatch(REFRESHTRANSCRIPTLINES, notification.pod.id, {root: true});
            }
          }

          // Refresh ML labels
          if (notification.code && notification.code == "AM38" && notification.message && notification.message.code && notification.message.code   == "NM31") {
            const viewPod = context.rootGetters[GETVIEWPOD];
            if (viewPod && viewPod.id && viewPod.id == notification.pod.id) {
              context.dispatch(REFRESHMLLABELS, notification.pod.id, {root: true});
            }
          }

          if (notification && notification.code && notification.code == "AM21" && notification.message && notification.message.code && notification.message.code == "NM22") {
            const snack = {
              // i18n: "common.serviceError",
              replaceTCode: "fileConversionComplete",
              replaceTParams: {p1: notification.pod.name},
              // replaceTFallback: "common.serviceUnknown",
              color: "success",
              showPodAssetsOnClick: true,
              pod: notification.pod,
            };
            context.commit(CREATESNACKBAR, snack, {root: true});
          }

          // Clean the messages after 3 seconds, if it is the latest one in the message queue.
          if (notification.queue && notification.queue === "end") {
            setTimeout(() => {
              context.commit(REMOVELAUNCHEDSERVICES, notification, {root: true});
            }, 3000);
          }

          break;
        case 'user-updated':
          context.dispatch(REFRESHDBUSER, null, { root: true });
          break;

        case 'limit-exceeded':
          context.dispatch('limitExceeded', notification);
          break;

        case 'node-created':
          context.dispatch(NODECREATEDNOTIFICATION, notification.node, { root: true });
          break;
        case 'node-updated':
          context.dispatch(NODEUPDATEDNOTIFICATION, notification.node, { root: true });
          break;
        case 'node-deleted':
          context.dispatch(NODEDELETEDNOTIFICATION, notification.node, { root: true });
          break;
        case 'node-moved':
          context.dispatch(NODEMOVEDNOTIFICATION, notification.node, { root: true });
          break;

        case 'pod-copy-job': {
          const pyaload = {
            nodeId: notification.nodeId,
            filesSize: notification.filesSize,
          };

          context.dispatch(PODCOPYJOBNOTIFICATION, pyaload, { root: true });
          break;
        }

        case 'pod-copied':
          context.dispatch(PODCOPIEDNOTIFICATION, notification, { root: true });
          break;
        case 'pod-moved':
          context.dispatch(PODMOVEDNOTIFICATION, notification.pod, { root: true });
          break;
        case 'pod-deleted':
          context.dispatch(
            PODDELETEDNOTIFICATION,
            notification?.podIds || notification?.podId || notification?.pod?.id,
            { root: true }
          );
          break;
        case 'node-copied':
          context.dispatch(NODECOPIEDNOTIFICATION, notification.node, { root: true });
          break;
        case 'user-disabled':
          context.dispatch(USERDISABLEDNOTIFICATION, notification.user, { root: true });
          break;
        case 'comments-updated':
          context.dispatch(PROMPTREFRESHCOMMENTS, notification.podid, { root: true });
          break;
        case 'message-received':
          context.commit('setNewNotification', {
            type: YELLOWNOTIFICATIONS,
            tenantId: notification.tenantId,
          });
          break;
        default:
          break;
      }
    },

    limitExceeded: function(context, notification) {
      const CREATESNACKBAR = context.rootGetters.keywords.MAIN.CREATESNACKBAR;


      if (!notification || !notification.resource || !notification.message) {
        console.log("The limit exceeded notification is missing data!")
        return;
      }

      const snack = {
        replaceTCode: notification.message.code,
        replaceTParams: notification.message.params,
        replaceTFallback: notification.message.content,
        color: '',
      };

      if (notification.resource.spentInPercentage >= 100) {
        context.commit('setNewNotification', { type: REDNOTIFICATIONS });
        snack.color = 'error';
      } else if (notification.resource.spentInPercentage >= 80) {
        context.commit('setNewNotification', { type: YELLOWNOTIFICATIONS });
        snack.color = 'primary';
      }

      snack.showLimitsOnClick = true;
      context.commit(CREATESNACKBAR, snack, {root: true});
    },
  },
}
