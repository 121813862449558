import Api from '../../api/Api'
import Vue from 'vue';
import Helper from '@/components/helper';

export default {
  namespaced: true,
  state: {
    allNodes: [],

    accessPointIds: [],
    currentNodeId: null,
    isLoadingAccessPoints: false,

    selectedNodeIds: [],
    iDeletedThoseNodeIds: [],

    nodeview: window.localStorage.nodeview || 'grid',
    viewNodeMoved: false,
    viewNodeDeleted: false,
  },
  getters: {
    getNodeById(state) {
      return function(nodeId) {
        return state.allNodes.find(x => x.id === nodeId);
      }
    },
    getChildrenById(state) {
      return function(parentId) {
        return state.allNodes
          .filter(x => x.parentid === parentId)
          .sort(function(a, b) {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });
      }
    },
    getNodesByType(state, getters, rootState, rootGetters) {
      return function(typeId) {
        const NODETYPES = rootGetters.keywords.COMMON.NODETYPES;
        const getNodeTypes = rootGetters[NODETYPES];

        const ROOTNODEID = rootGetters.keywords.AUTH.ROOTNODEID;
        const getRootNodeId = rootGetters[ROOTNODEID];

        const GETNODEBYID = rootGetters.keywords.NODE.GETNODEBYID;

        return state.allNodes
        .filter(x => x.type === typeId)
        .filter(function(x) {
          // For projects and clients we need to match parent nodes
          // Note: we are assuming that clients are located right inside the tenant folder
          // and the projects are located right inside the tenant or client folders
          if (getRootNodeId && (typeId === getNodeTypes.PROJECT || typeId === getNodeTypes.CLIENT)) {
            if (x.parentid && x.parentid === getRootNodeId) {
              return true;
            }

            if (x.parentid) {
              const parent = rootGetters[GETNODEBYID](x.parentid);
              if (parent && parent.parentid === getRootNodeId) {
                return true;
              }
            }

            return false;
          }

          return true;
        })
        .sort(function(a, b) {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
      }
    },

    getAccessPoints: function(state) {
      return state.allNodes.filter(x => state.accessPointIds.indexOf(x.id) >= 0);
    },
    getIsLoadingAccessPoints: function(state) {
      return state.isLoadingAccessPoints;
    },
    getCurrentNode: function(state) {
        return state.allNodes.find(x => x.id === state.currentNodeId);
    },
    getChildNodes: function(state) {
      if (!state.currentNodeId) {
        return [];
      }

      return state.allNodes
        .filter(x => x.parentid === state.currentNodeId)
        .sort(function(a, b) {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });
    },
    getArchivedNodes: function(state, getters, rootState, rootGetters) {
      const GETNODEBYID = rootGetters.keywords.NODE.GETNODEBYID;

      return function (accessPointId) {
        return state.allNodes
          .filter(x => x.archived)
          .filter(function(x) {
            if (!x.parentid) {
              return false
            }

            const parent = rootGetters[GETNODEBYID](x.parentid);
            if (parent.archived) {
              return false;
            }

            if (x.parentid === accessPointId) {
              return true;
            }

            if (parent && parent.parentid === accessPointId) {
              return true;
            }

            return false;
          });
      }
    },

    getSelectedNodes: function(state) {
      return state.allNodes.filter(x => state.selectedNodeIds.indexOf(x.id) >= 0);
    },
    getSelectedNodeIds: function (state) {
      return state.selectedNodeIds;
    },
    getNodeView: function(state) {
      return state.nodeview;
    },
    isViewNodeMoved: function(state) {
      return state.viewNodeMoved;
    },
    isViewNodeDeleted: function(state) {
      return state.viewNodeDeleted;
    },
    getCurrentNodeId: function (state) {
      return state.currentNodeId;
    },
  },
  mutations: {
    addOrUpdateNodes(state, nodes) {

      if (!Array.isArray(nodes)) {
        nodes = [nodes];
      }

      // Make a flat structure of the nodes and their children/parents
      let nodesToUpdate = [];
      for (let i = 0; i < nodes.length; i++) {
        nodesToUpdate.push(nodes[i]);
        if (nodes[i].children) {
          for (let o = 0; o < nodes[i].children.length; o++) {
            nodesToUpdate.push(nodes[i].children[o]);
          }
        }
        if (nodes[i].parent) {
          nodesToUpdate.push(nodes[i].parent);
        }
        if (nodes[i].parent && nodes[i].parent.parent) {
          nodesToUpdate.push(nodes[i].parent.parent);
        }
      }

      // Try to update each node
      for (let k = 0; k < nodesToUpdate.length; k++) {
        let isNodeUpdated = false;
        for (let l = 0; l < state.allNodes.length; l++) {
          if (state.allNodes[l].id == nodesToUpdate[k].id) {
            // Object.assign(state.allNodes[l], nodesToUpdate[k]);
            Vue.set(state.allNodes, l, nodesToUpdate[k]);
            isNodeUpdated = true;
            break;
          }
        }

        // Insert the node in the list of nodes
        if (!isNodeUpdated) {
          state.allNodes.push(nodesToUpdate[k]);
        }
      }
    },

    setAccessPoints: function(state, nodes) {
      state.accessPointIds = nodes.map(x => x.id);
    },
    setCurrentNode: function(state, node) {
      if (node) {
        state.currentNodeId = node.id;
      } else {
        state.currentNodeId = null;
      }

      state.selectedNodeIds = [];
      state.viewNodeDeleted = false;
      state.viewNodeMoved = false;
    },
    setViewNodeDeleted: function(state, nodeID) {
      // No need to address this change beacuse i deleted this node
      if (state.iDeletedThoseNodeIds.indexOf(nodeID) >= 0) {
        return;
      }

      if (state.currentNodeId) {
        const currentNode = state.allNodes.find(x => x.id == state.currentNodeId);

        const ids = currentNode.rootpath.split('/');
        if (ids.indexOf(nodeID.toString()) >= 0) {
          state.viewNodeDeleted = true;
        }
      }
    },
    setViewNodeMoved: function(state, nodeID) {
      if (state.currentNodeId) {
        const currentNode = state.allNodes.find(x => x.id == state.currentNodeId);

        const ids = currentNode.rootpath.split('/');
        if (ids.indexOf(nodeID.toString()) >= 0) {
          state.viewNodeMoved = true;
        }
      }
    },
    deleteNodes: function(state, nodeIDs) {
      if (nodeIDs.indexOf(state.currentNodeId) >= 0) {
        state.currentNodeId = null;
      }

      const clonedNodes = [];
      for (let i = state.allNodes.length - 1; i >= 0; i--) {
        if (nodeIDs.indexOf(state.allNodes[i].id) >= 0) {
          continue;
        }

        clonedNodes.push(state.allNodes[i]);
      }

      state.allNodes = clonedNodes;
    },
    addIDeletedThoseNodeIds: function(state, nodeIds) {
      for (let i = 0; i < nodeIds.length; i++) {
        if (state.iDeletedThoseNodeIds.indexOf(nodeIds[i]) < 0) {
          state.iDeletedThoseNodeIds.push(nodeIds[i]);
        }
      }
    },
    removeIDeletedThoseNodeIds: function(state, nodeIds) {
      for (let i = 0; i < nodeIds.length; i++) {
        const idIndex = state.iDeletedThoseNodeIds.indexOf(nodeIds[i]);

        if (idIndex >= 0) {
          state.iDeletedThoseNodeIds.splice(idIndex, 1);
        }
      }
    },
    deleteLogo: function(state, nodeID) {
      for (let item of state.allNodes) {
        if (item.id == nodeID && item.clientlogourl) {
          // delete item.clientlogourl;
          Vue.delete(item, 'clientlogourl');
          break;
        }
      }
    },
    toggleSelection: function(state, node) {
      let index = state.selectedNodeIds.indexOf(node.id);

      if (index >= 0) {
        state.selectedNodeIds.splice(index, 1);
      } else {
        state.selectedNodeIds.push(node.id);
      }
    },
    deselectAllNodes: function(state) {
      state.selectedNodeIds = [];
    },
    toggleNodeView: function(state) {
      if (state.nodeview == 'grid') {
        state.nodeview = 'list';
      } else {
        state.nodeview = 'grid';
      }

      window.localStorage.nodeview = state.nodeview;
    },
    setNodeView: function(state, nodeView) {
      state.nodeview = (nodeView === 'list') ? 'list' : 'grid';

      window.localStorage.nodeview = state.nodeview;
    },
    updateNodeProperty: function(state, payload) {
      for (let i = 0; i < state.allNodes.length; i++) {
        if ((state.allNodes[i].id === payload.nodeId) && (payload.propertyName in state.allNodes[i])) {
          state.allNodes[i][payload.propertyName] = payload.newValue;
        }
      }
    },
    setIsLoadingAccessPoints: function(state, payload) {
      state.isLoadingAccessPoints = payload;
    }
  },
  actions: {
    // used in the side node menu
    // get node info with child nodes
    // used in the move/copy nodes and pods dialogs
    getNodeInfo: function(context, nodeID) {
      return Api.node.getNodeByID(nodeID)
      .then(function(response) {
        context.commit('addOrUpdateNodes', response.data);
        return response.data;
      })
    },
    // getting child nodes and pods of specific node
    // used in the nodes listing view component
    getFullNodeInfo: function(context, nodeID) {
      return Api.node.getNodeByID(nodeID)
      .then(function(response) {
        context.commit('addOrUpdateNodes', response.data);
        return response;
      });
    },
    refreshAccessPoints: function(context) {
      context.commit('setIsLoadingAccessPoints', true);
      return Api.node.getAccessPoints()
        .then(function(result) {
          context.commit('addOrUpdateNodes', result.data);
          context.commit('setAccessPoints', result.data);

          return result.data;
        })
        .finally(function () {
          context.commit('setIsLoadingAccessPoints', false);
        });
    },
    setActiveNode: async function(context, nodeID) {
      return Api.node.getNodeByID(nodeID)
      .then(function(result) {
        const currentNode = result.data;

        context.commit('addOrUpdateNodes', currentNode);
        context.commit('setCurrentNode', currentNode);

        //the following block is used to determine if in the FCP extension and to attach the node id to it
        if (Helper.isFCPMessageHandler('setNode')) {
          const nodeObject = JSON.stringify({action: "setNode", nodeID: nodeID});
          Helper.invokeFCPMessageHandler('setNode', nodeObject);
        }

        return result.data;
      })
    },
    viewNode: async function(context, nodeID) {
      // console.log("View node!!!")

      // NOTE: this is used in the pod store - create new pod, addOrUpdatePod
      // Fixing issue #10601
      window.isNodeTransitioning = true;

      // clear up the selected and viewing pods and assets
      const RESETPODS = context.rootGetters.keywords.POD.RESETPODS;
      await context.dispatch(RESETPODS, null, {root: true});

      let result = await context.dispatch('setActiveNode', nodeID);
      if (result && result.pods && result.pods.length > 0) {
        const ADDORUPDATEPODS = context.rootGetters.keywords.POD.ADDORUPDATEPODS;
        await context.dispatch(ADDORUPDATEPODS, result.pods, {root: true});
      }

      // This is not needed because we are getting the pods with the node get request
      // const REFRESHPODS = context.rootGetters.keywords.POD.REFRESHPODS;
      // await context.dispatch(REFRESHPODS, nodeID, {root: true});

      window.isNodeTransitioning = false;

      return result;
    },
    viewRoots: function(context) {
      context.commit('setCurrentNode', null);

      const RESETPODS = context.rootGetters.keywords.POD.RESETPODS;
      return context.dispatch(RESETPODS, null, {root: true});
    },
    createNode: function(context, params) {
      if (!params.note) {
        params.note = "";
      }

      return Api.node.createNode(params)
      .then(function(result) {
        context.commit('addOrUpdateNodes', result.data);
        return result.data;
      });
    },
    updateNode: function(context, params) {
      const nodeID = params.id;
      delete params.id;

      if (!params.note) {
        params.note = "";
      }

      return Api.node.updateNode(params, nodeID)
      .then(function(result) {
        context.commit('addOrUpdateNodes', result.data);
        return result.data;
      });
    },
    updateFolder: function(context, payload) {
      const nodeId = payload.id;
      const data = {
        name: payload.name,
        note: payload.note,
        teamonly: payload.teamonly,
      };

      return Api.node.updateFolder(nodeId, data)
      .then(function(result) {
        context.commit('addOrUpdateNodes', result.data);
        return result.data;
      });
    },
    getUsersForNodes: async function(context, nodeIDs) {
      let promises = nodeIDs.map(x => Api.node.getUsers(x));
      let results = await Promise.all(promises);
      let resEnabled = results.map((r) => r.data.usercount);
      let resDisabled = results.map((r) => r.data.disabledusercount);
      const sumEnabled = resEnabled.reduce((a, c) => a + c, 0);
      const sumDisabled = resDisabled.reduce((a, c) => a + c, 0);
      return {"enabled": sumEnabled, "disabled": sumDisabled};
    },
    deleteNodes: async function(context, nodeIDs) {
      context.commit("addIDeletedThoseNodeIds", nodeIDs);

      let promises = nodeIDs.map(x => Api.node.deleteNode(x));

      try {
        await Promise.all(promises);
      } catch (error) {
        context.commit("removeIDeletedThoseNodeIds", nodeIDs);
        throw error;
      }

      // Do not commit state-change.
      // Instead wait for push notification to arrive.
      // context.commit("deleteNodes", nodeIDs);

      // Checkout US #12212 for the reason why.
      // We just keep a list of deleted node IDs by me,
      // so that we know when NOT to show the NodeDeletedDialog.
      // We should show the NodeDeletedDialog only when
      // somebody else deletes the node we are currently in.

    },
    deleteTenants: async function(context, tenantIDs) {
      let promises = tenantIDs.map(x => Api.tenant.deleteTenant(x));
      await Promise.all(promises);
      context.commit("deleteNodes", tenantIDs);
    },
    deleteLogo: function(context, nodeID) {
      return Api.node.deleteLogo(nodeID)
      .then(function(response) {
        console.log("Node logo deleted", response);
        context.commit('deleteLogo', nodeID);
      });
    },
    toggleSelection: function(context, node) {
      context.commit('toggleSelection', node);
    },
    deselectAllNodes: function(context) {
      context.commit('deselectAllNodes');
    },
    toggleNodeView: function(context) {
      context.commit('toggleNodeView');

      const CHANGEFOLDERVIEW = context.rootGetters.keywords.ACCOUNT.CHANGEFOLDERVIEW;
      context.dispatch(CHANGEFOLDERVIEW, { view: context.getters.getNodeView }, {root: true});
      // .then(() => {
      //   console.log('The folder view was changed successfully.');
      // }).catch((error) => {
      //   console.error(error);
      // });
    },
    getSubnodes: function(context, payload) {
      return Api.node.getSubnodes(payload.nodeid, payload.type)
      .then(function(response) {
        context.commit('addOrUpdateNodes', response.data);
        return response.data;
      });
    },
    moveManyNodes: function(context, payload) {
      return Api.node.moveManyNodes(payload)
      .then(function(response) {
        response;   // not used
        // console.log("Move many nodes response", response);
      });
    },
    copyManyNodes: function(context, payload) {
      return Api.node.copyManyNodes(payload);
    },
    updateNote: function(context, payload) {
      return Api.node.updateNote(payload);
    },
    updateProjectStatus: function(context, params) {
      return Api.node.updateProjectStatus(params, params.id)
      .then(function(result) {
        context.commit('addOrUpdateNodes', result.data);
        return result.data;
      });
    },
    renameNode: function(context, params) {
      const nodeID = params.id;
      delete params.id;

      return Api.node.renameNode(params, nodeID)
        .then(function(result) {
          context.commit('addOrUpdateNodes', result.data);
          return result.data;
        })
        .catch(function(error) {
          console.log("Error updating node:");
          console.log(error);
        });
    },
    getProperties: function(context, nodeID) {
      return Api.node.getProperties(nodeID)
      .then(function(response) {
        return response.data;
      });
    },
    nodeCreatedNotification: function(context, node) {
      context.commit('addOrUpdateNodes', node);
    },
    nodeUpdatedNotification: function(context, node) {
      context.commit('addOrUpdateNodes', node);
    },
    nodeDeletedNotification: function(context, node) {
      context.commit('setViewNodeDeleted', node.id);

      const nodeIdsForDeletion = node.childIds ? [node.id, ...node.childIds] : [node.id];

      context.commit('deleteNodes', nodeIdsForDeletion);
    },
    nodeMovedNotification: function(context, node) {
      context.commit('setViewNodeMoved', node.id);
      context.commit('addOrUpdateNodes', node);
    },
    nodeCopiedNotification: function(context, node) {
      context.commit('addOrUpdateNodes', node);
    },
    softDeleteTenant: async function(context, tenantId) {
      return Api.node.softDeleteTenant(tenantId);
    },
  }
}
