import { render, staticRenderFns } from "./RecycleBinConfirmationDialog.vue?vue&type=template&id=05901c30&scoped=true&"
import script from "./RecycleBinConfirmationDialog.vue?vue&type=script&lang=js&"
export * from "./RecycleBinConfirmationDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05901c30",
  null
  
)

export default component.exports