<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mx-1"
        height="48px"
        width="48px"
        color="primary"
        style="border-radius: 24px !important;"
        elevation=0
        large
        fab
        data-cy="topbar-beeUserMenu-button"
      >
        <img v-if="getDarkMode" class="" src="../../../../assets/images/smalltw-dark.png" style="width: 30px; height: 30px;" :alt="$t('menus.topBars.toggleAccountMenu')"  />
        <img v-if="!getDarkMode" class="" src="../../../../assets/images/smalltw-light.png" style="width: 30px; height: 30px;" :alt="$t('menus.topBars.toggleAccountMenu')" />
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item>
        <div class="d-flex flex-column justify-start user-menu-contact">
          <span>{{ $t('layouts.main.contactHeading') }}</span>
          <span>support@tubeway.io</span>
          <span>+31 (0) 353 033 266</span>
        </div>
      </v-list-item>

      <v-divider />

      <v-list-item data-cy="topbar-beeUserMenu-toggleLightDarkMode">
        <v-switch
          v-model="isDarkMode"
          @change="toggleDarkMode"
          style="margin-top: 0px;"
          :label="$t('layouts.main.darkMode')"
          dense
          hide-details
        >
        </v-switch>
      </v-list-item>

      <v-divider/>

      <v-list-item
        v-if="forceManagementConsole || $vuetify.breakpoint.smAndDown"
        to="/console/index"
        data-cy="beeUserMenu-managementConsole"
      >
        {{ $t('layouts.main.managementConsole') }}
      </v-list-item>

      <v-list-item to="/account/logout" data-cy="topbar-beeUserMenu-logout">
        {{ $t('layouts.main.logout') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "BeeUserMenu",
  props: {
    forceManagementConsole: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: function() {
    return {
      isDarkMode: false,
    };
  },
  mounted: function() {
    setTimeout(() => {
      this.isDarkMode = this.getDarkMode;
    }, 1500);
  },
  methods: {
    toggleDarkMode() {
      const SETDARKMODE = this.$store.getters.keywords.MAIN.SETDARKMODE;
      this.$store.commit(SETDARKMODE, !this.getDarkMode);

      const CHANGETHEME = this.$store.getters.keywords.ACCOUNT.CHANGETHEME;
      this.$store.dispatch(CHANGETHEME, { theme: this.getDarkMode ? 'dark' : 'light' })
        .then(() => {
          console.log('The theme was changed successfully.');
        }).catch((error) => {
          console.error(error);
      });
    },
  },
  computed: {
    getDarkMode() {
      const GETDARKMODE = this.$store.getters.keywords.MAIN.GETDARKMODE;
      return this.$store.getters[GETDARKMODE];
    },
  }
}
</script>

<style>
.user-menu-contact {
  padding-bottom: 0.75rem;
}

.user-menu-contact > span {
  line-height: 2rem;
}
</style>
