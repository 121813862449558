import Api from '../../api/Api';

export default {
  namespaced: true,
  state: {
    nodes: [],
    selectedNode: null,
    retentionPeriod: 90,  // Mind you, this must be in sync with the backend!
    isRefreshingNodes: false,
  },
  getters: {
    getAllNodes: function (state) {
      return state.nodes;
    },


    getNodeById: function (state) {
      return (nodeId) => state.nodes.find((node) => node.id === nodeId);
    },
    getChildNodes: function (state) {
      return (parentNodeId) => state.nodes.filter((node) => node.parentid === parentNodeId);
    },
    totalPods: function(state) {
      if (!state.nodes) {
        return 0;
      }

      return state.nodes.reduce((sum, node) => (sum += node.pods.length), 0);
    },
    selectedNode: function (state) {
      return state.selectedNode;
    },
    getRetentionPeriod: function (state) {
      return state.retentionPeriod;
    },
    getIsRefreshingNodes: function (state) {
      return state.isRefreshingNodes;
    },
  },
  mutations: {
    setNodes: function(state, nodes) {
      state.nodes = nodes;
    },
    setRetentionPeriod: function (state, period) {
      state.retentionPeriod = period;
    },
    setIsRefreshingNodes: function (state, value) {
      console.assert(typeof value === 'boolean');
      state.isRefreshingNodes = value;
    },
    removePods: function(state, podIds) {
      const deleteNodes = new Set();

      let node;
      for (const podId of podIds) {
        node = state.nodes.find((node) => node.pods.some((pod) => pod.id === podId));

        if (node) {
          if (node.pods.length === 1) {
            deleteNodes.add(node);
          } else {
            node.pods = node.pods.filter((pod) => pod.id !== podId);
          }
        }
      }

      const newNodesState = state.nodes.filter((node) => !deleteNodes.has(node));
      if (deleteNodes.size > 0) {
        state.nodes = newNodesState;
      }
    },
    setSelectedNode: function (state, nodeId) {
      if (nodeId === null) {
        state.selectedNode = null;
      } else if (!state.nodes.some((node) => node.id === nodeId)) {
        console.error(`Unable to find node with id ${nodeId}`);
      } else {
        state.selectedNode = nodeId;
      }
    },
    updateRecycleBinPod(state, pod) {
      for (let i = 0; i < state.nodes.length; i++) {
        if (Array.isArray(state.nodes[i].pods)) {
          for (let j = 0; j < state.nodes[i].pods.length; j++) {
            if (state.nodes[i].pods[j].id === pod.id) {
              Object.assign(state.nodes[i].pods[j], pod);
            }
          }
        }
      }
    }
  },
  actions: {
    refreshNodesList: function (context, rootNodeId) {
      context.commit('setIsRefreshingNodes', true);

      return Api.recycleBin.listNodes(rootNodeId).then((results) => {
        context.commit('setNodes', results.data);
      }).finally(() => {
        context.commit('setIsRefreshingNodes', false);
      });
    },
    permanentlyDeletePods: function (context, podIds) {
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = context.rootGetters[ROOTNODEID];

      return Api.recycleBin.permanentlyDeletePods(rootNodeId, podIds).then(() => {
        context.commit('removePods', podIds);
      });
    },
    restorePods: function (context, podIds) {
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = context.rootGetters[ROOTNODEID];

      return Api.recycleBin.restorePods(rootNodeId, podIds).then(() => {
        context.commit('removePods', podIds);
      });
    },
    emptyRecycleBin: function (context) {
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const rootNodeId = context.rootGetters[ROOTNODEID];

      return Api.recycleBin.emptyRecycleBin(rootNodeId).then(() => {
        context.commit('setNodes', []);
      });
    },
    updateRecycleBinPod: function(context, pod) {
      context.commit('updateRecycleBinPod', pod);
    }
  },
}
