<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>

    <div class="vb-dialog vb-dialog-danger">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>
            {{
              type === 'empty'
                ? $t('routes.recycleBin.emptyRecycleBin')
                : $t('routes.recycleBin.permanentlyDeleteSelected')
            }}
          </span>
          <v-btn icon @click="closeDialog" :disabled="inProgress">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5">
          {{
            type === 'empty'
              ? $t('routes.recycleBin.confirmEmptyRecycleBin')
              : $t('routes.recycleBin.confirmDeleteSelected')
          }}
          <div v-if="errorMessage" class="error">
            {{ errorMessage }}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn elevation="0" :disabled="inProgress" @click="$emit('confirm')" class="btnOk">
            {{ $t('common.confirm') }}
          </v-btn>
          <v-btn elevation="0" :disabled="inProgress" @click="closeDialog" class="btnCancel">
            {{ $t('common.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "RecycleBinConfirmationDialog",
  data: function () {
    return {
      dialog: false,
    };
  },
  props: {
    isShowingDialog: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
    inProgress: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    isShowingDialog(val) {
      if (!val) {
        this.dialog = false;
      }
    }
  },
  emits: ['confirm', 'cancel'],
  methods: {
    closeDialog() {
      this.$emit('cancel');
      this.dialog = false;
    },
  }
}
</script>

<style scoped>
</style>
