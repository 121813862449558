
export default {
  MAIN: {
    GETDARKMODE: 'main/getDarkMode',
    SETDARKMODE: 'main/setDarkMode',
    GETSIDEMENUTOGGLE: 'main/getSidemenuToggle',
    TOGGLESIDEMENU: 'main/toggleSidemenu',

    GETLAYOUT: 'main/layout',
    SETLAYOUT: 'main/setLayout',
    GETACTIVEMENU: 'main/getActiveMenu',
    SETACTIVEMENU: 'main/setActiveMenu',
    GETPODRIGHTMENU: 'main/getPodRightMenu',
    SETPODRIGHTMENU: 'main/setPodRightMenu',
    GETACTIVEMENUVIEW: 'main/getActiveMenuView',
    SETACTIVEMENUVIEW: 'main/setActiveMenuView',
    GETSNACKBARS: 'main/getSnackbars',
    CREATESNACKBAR: 'main/createSnackbar',
    REMOVESNACKBAR: 'main/removeSnackbar',
  },
  AUTH: {
    GETUSER: 'auth/user',
    GETDBUSER: 'auth/dbUser',

    GETTENANTS: 'auth/getTenants',
    GETACTIVETENANT: 'auth/getActiveTenant',
    GETACTIVETENANTID: 'auth/getActiveTenantId',

    GETACCESSPOINTS: 'auth/getAccesspoints',
    GETACCESSPOINTIDS: 'auth/getAccessPointIds',
    GETACTIVEACCESSPOINT: 'auth/getActiveAccesspoint',
    ROOTNODEID: 'auth/rootNodeId',

    SIGNIN: 'auth/signIn',
    SIGNOUT: 'auth/signOut',
    CONFIRM: 'auth/confirmSignUp',
    SETUPTOTP: 'auth/setupTOTP',
    VERIFYTOTPTOKEN: 'auth/verifyTOTPToken',
    ENABLETOTPMFA: 'auth/enableTOTPMFA',
    DISABLETOTPMFA: "auth/disableTOTPMFA",
    CONFIRMTOTP: 'auth/confirmTOTP',
    FETCHMFAPREFERENCE: 'auth/fetchMFAPreference',
    REFRESHCOGNITO: 'auth/refreshCognitoUser',
    REFRESHDBUSER: 'auth/refreshDBUser',
    GETCURRENTSESSION: 'auth/getCurrentSession',
    FORGOTPASSWORD: 'auth/forgotPassword',
    RESETPASSWORD: 'auth/forgotPasswordSubmit',
    CHANGEPASSWORD: 'auth/changePassword',
    COMPLETEPASSWORD: 'auth/completePassword',
    SAVESETTINGS: 'auth/saveSettings',
    TENANTISEXPIRED: 'auth/tenantIsExpired',
    SUBSCRIPTIONEXPIRETIMESTAMP: 'auth/subscriptionExpireTimestamp',
    CREATEFREETRAIL: 'auth/createFreeTrial',
    SALESMANCREATESTENANT: 'auth/salesmanCreatesTenant',
    ISFORCELOGOUT: 'auth/forceLogout',
    UPDATEFORCELOGOUT: 'auth/updateForceLogout',
    USERDISABLEDNOTIFICATION: 'auth/disableUser',
    UPDATEDBUSERPROPERTY: 'auth/updateDBUserProperty',
    UPDATEUSERSETTINGVALUE: 'auth/updateUserSettingValue',
    ISROOTUSER: 'auth/isRootUser',
    SETROOTNODEID: 'auth/setRootNodeId',
  },
  UPLOAD: {
    FILES: 'upload/files',
    RESETFILES: 'upload/resetFiles',
    STATUSES: 'upload/statuses',
    ADDFILES: 'upload/addFiles',
    REMOVEFILE: 'upload/removeFile',
    UPLOADFILE: 'upload/uploadFile',
    UPLOADALL: 'upload/uploadAllFiles',
    SETASSETTYPE: 'upload/setAssetType',
    SETASSETTYPES: 'upload/setAssetTypes',
    TOGGLETEAMONLY: 'upload/toggleTeamonly',
    SETNOTE: 'upload/setNote',
    GETMAINVIEWDROPFILESEVENT: 'upload/getMainviewDropfilesEvent',
    SETMAINVIEWDROPFILESEVENT: 'upload/setMainviewDropfilesEvent',
  },
  COMMON: {
    NODETYPES: 'common/nodeTypes',
    PROJECTSTATUSES: 'common/projectStatuses',
    PODSTATES: 'common/podStates',
    ASSETSTATES: 'common/assetStates',
    PODBADGESTATUSES: 'common/podBadgeStatuses',
    ARCHIVESTATUS: 'common/archiveStatus',
    INTERFACELANGUAGES: 'common/interfaceLanguages',
    REFRESHLANGUAGES: 'common/refreshLanuages',
    DBLANGUAGES: 'common/dbLanguages',
    ASSETTYPES: 'common/assetTypes',
    USERTYPES: 'common/userTypes',
    USERTYPE: 'common/userType',
    DOWNLOADTHINGS: 'common/downloadThings',
    SENDSUPPORTTICKET: 'common/sendSupportTicket',
    SENDFEATUREREQUEST: 'common/sendFeatureRequest',
    ROOTFOLDERID: 'common/rootFolderId',
  },
  POD: {
    GETPODS: 'pod/getPods',
    GETVIEWPOD: 'pod/getViewPod',
    SETISVIEWPODMOVED: 'pod/setViewPodMoved',
    ISVIEWPODMOVED: 'pod/isViewPodMoved',
    SETISVIEWPODDELETED: 'pod/setViewPodDeleted',
    ISVIEWPODDELETED: 'pod/isViewPodDeleted',
    SETISVIEWPODARCHIVED: 'pod/setViewPodArchived',
    ISVIEWPODARCHIVED: 'pod/isViewPodArchived',
    ISVIDEOPOD: 'pod/isVideoPod',
    ISIMAGEPOD: 'pod/isImagePod',
    ISAUDIOPOD: 'pod/isAudioPod',
    ISARCHIVEPOD: 'pod/isArchivePod',
    GETPODMETA: 'pod/getPodMeta',
    GETPODACTIONS: 'pod/getPodActions',
    REFRESHPODACTIONS: 'pod/refreshPodActions',
    GETSELECTEDPODS: 'pod/getSelectedPods',
    GETSELECTEDPODIDS: 'pod/getSelectedPodIds',
    GETSELECTEDVIDEOPODS: 'pod/getSelectedVideoPods',
    GETSELECTEDAUDIOPODS: 'pod/getSelectedAudioPods',
    GETSELECTEDIMAGEPODS: 'pod/getSelectedImagePods',
    GETSELECTEDASSETS: 'pod/getSelectedAssets',
    GETSUBTITLEASSETS: 'pod/getSubtitleAssets',
    FETCHPODBYID: 'pod/fetchPodById',
    FETCHPODSBYNODEID: 'pod/fetchPodsByNodeId',
    GETPODSBYNODEID: 'pod/getPodsByNodeId',
    GETPODBYID: 'pod/getPodById',
    REFRESHPODS: 'pod/refreshPods',
    RESETPODS: 'pod/resetPods',
    REFRESHPODMETA: 'pod/refreshPodMeta',
    VIEWPOD: 'pod/viewPod',
    CREATEPOD: 'pod/createPod',
    UPDATEPOD: 'pod/updatePod',
    UPDATEPODNOTE: 'pod/updatePodNote',
    DELETEPODS: 'pod/deletePods',
    CREATEPODASSET: 'pod/createPodAsset',
    UPDATEPODASSET: 'pod/updatePodAsset',
    DELETEPODASSET: 'pod/deletePodAsset',
    DELETEPODASSETS: 'pod/deletePodAssets',
    PROMOTETOPOD: 'pod/promoteAssetToPod',
    CONVERTSUBTITLE: 'pod/convertSubtitle',
    CREATESUBTITLEASSET: 'pod/createSubtitleAsset',
    UPDATESUBTITLEASSET: 'pod/updateSubtitleAsset',
    SETASSETLANGUAGE: 'pod/setAssetLanguage',
    MOVEMANYPODS: 'pod/moveManyPods',
    COPYMANYPODS: 'pod/copyManyPods',
    TOGGLESELECTEDPOD: 'pod/toggleSelectedPod',
    DESELECTALLPODS: 'pod/deselectAllPods',
    TOGGLESELECTEDASSET: 'pod/toggleSelectedAsset',
    TRANSCODEFILE: 'pod/transcodeFile',
    EXTRACTARCHIVE: 'pod/extractArchive',
    GENERATEAUDIO: 'pod/generateAudio',
    GETPODVERSIONS: 'pod/getPodVersions',
    ADDVERSION: 'pod/addVersion',
    REORDERVERSIONS: 'pod/reorderVersions',
    ADDORUPDATEPOD: 'pod/addOrUpdatePod',
    UPDATEORIGNOREPOD: 'pod/updateOrIgnorePod',
    UPDATESTATEMESSAGE: 'pod/updateStateMessage',
    ADDLAUNCHEDSERVICE: 'pod/addLaunchedService',
    PODMOVEDNOTIFICATION: 'pod/podMovedNotification',
    PODCOPIEDNOTIFICATION: 'pod/podCopiedNotification',
    PODCOPYJOBNOTIFICATION: 'pod/podCopyJobNotification',
    PODDELETEDNOTIFICATION: 'pod/podDeletedNotification',
    UPDATELAUNCHEDSERVICES: 'pod/updateLaunchedServices',
    REMOVELAUNCHEDSERVICES: 'pod/removeLaunchedServices',
    SETACTIVETOOLBAR: 'pod/setActiveToolbar',
    GETACTIVETOOLBAR: 'pod/getActiveToolbar',
    UPDATETEAMONLY: 'pod/updateTeamOnly',
    RENAMEFILE: 'pod/renameFile',
    ADDORUPDATEPODS: 'pod/addOrUpdatePods',
    COMMENT: {
      GETCOMMENTS: 'pod/comment/getComments',
      GETCOMMENTSCOUNT: 'pod/comment/getCommentsCount',
      PROMPTREFRESHCOMMENTS: 'pod/comment/promptRefreshComments',
      CREATECOMMENT: 'pod/comment/createComment',
      UPDATECOMMENT: 'pod/comment/updateComment',
      DELETECOMMENT: 'pod/comment/deleteComment',
      SUBMITCOMMENTS: 'pod/comment/submitComments',
      GETCOMMENTSSTATELESS: 'pod/comment/getCommentsStateless',
      LIKECOMMENT: 'pod/comment/likeComment',
      DISLIKECOMMENT: 'pod/comment/dislikeComment',
      CHECKCOMMENT: 'pod/comment/checkComment',
      UNCHECKCOMMENT: 'pod/comment/uncheckComment',
      GETCLOSEFORMSEVENT: 'pod/comment/getCloseFormsEvent',
      SETCLOSEFORMSEVENT: 'pod/comment/setCloseFormsEvent',
      GETVISIBLEFORMS: 'pod/comment/getVisibleForms',
      INCREASEVISIBLEFORMS: 'pod/comment/increaseVisibleForms',
      DECREASEVISIBLEFORMS: 'pod/comment/decreaseVisibleForms',
      REFRESHCOMMENTS: 'pod/comment/refreshComments',
      RESETCOMMENTS: 'pod/comment/resetComments'
    },
    TRANSCRIPT: {
      REFRESHTRANSCRIPTLINES: 'pod/transcript/refreshTranscriptLines',
      GETTRANSCRIPTS: 'pod/transcript/getTranscripts',
      CREATETRANSCRIPT: 'pod/transcript/createTranscript',
      UPDATETRANSCRIPT: 'pod/transcript/updateTranscript',
      DELETETRANSCRIPT: 'pod/transcript/deleteTranscript',
      CREATETRANSCRIPTLINE: 'pod/transcript/createTranscriptLine',
      UPDATETRANSCRIPTLINE: 'pod/transcript/updateTranscriptLine',
      DELETETRANSCRIPTLINE: 'pod/transcript/deleteTranscriptLine',
      GETDYNAMICVTTFILES: 'pod/transcript/getDynamicVTTFiles',
      GENERATEFILE: 'pod/transcript/generateFile',
    },
    LABEL: {
      REFRESHLABELS: 'pod/label/refreshLabels',
      DELETELABEL: 'pod/label/deleteLabel',
      CREATELABEL: 'pod/label/createLabel',
      UPDATELABEL: 'pod/label/updateLabel',
      GETLABELS: 'pod/label/getLabels',
      CREATELABELS: 'pod/label/createLabels',

      REFRESHMLLABELS: 'pod/label/refreshMlLabels',
      GETMLLABELS: 'pod/label/getMlLabels',
      UPDATEMLLABEL: 'pod/label/updateMlLabel',
      DELETEMLLABEL: 'pod/label/deleteMlLabel',
      GETCOMBINEDAWSDETECTEDLABELS: 'pod/label/getCombinedAWSDetectedLabels',
    },
    REVIEWER: {
      INVITEREVIEWERS: 'pod/reviewer/inviteReviewers',
      REFRESHUSERS: 'pod/reviewer/refreshUsersWithAccessToPod',
      REFRESHREVIEWERS: 'pod/reviewer/refreshReviewers',
      GETUSERS: 'pod/reviewer/getUsersWithAccessToPod',
      GETREVIEWERS: 'pod/reviewer/getReviewers',
    },
  },
  NODE: {
    VIEWNODE: 'node/viewNode',
    VIEWROOTS: 'node/viewRoots',
    CREATENODE: 'node/createNode',
    UPDATENODE: 'node/updateNode',
    UPDATEFOLDER: 'node/updateFolder',
    DELETENODES: 'node/deleteNodes',
    DELETETENANTS: 'node/deleteTenants',
    SOFTDELETETENANT: 'node/softDeleteTenant',
    DELETELOGO: 'node/deleteLogo',
    GETNODEBYID: 'node/getNodeById',
    CHILDNODES: 'node/getChildNodes',
    GETCHILDRENBYID: 'node/getChildrenById',
    GETNODESBYTYPE: 'node/getNodesByType',
    GETARCHIVEDNODES: 'node/getArchivedNodes',
    GETCURRENTNODEID: 'node/getCurrentNodeId',

    CURRENTNODE: 'node/getCurrentNode',
    GETCURRENTNODE: 'node/getCurrentNode',

    SETACTIVENODE: 'node/setActiveNode',
    ACCESSPOINTS: 'node/getAccessPoints',
    REFRESHACCESSPOINTS: 'node/refreshAccessPoints',
    ISLOADINGACCESSPOINTS: 'node/getIsLoadingAccessPoints',
    // GETEDITNODE: 'node/getEditNode',
    // SETEDITNODE: 'node/setEditNode',
    // GETBROWSENODE: 'node/getBrowseNode',
    // BROWSENODE: 'node/browseNode',
    GETNODEINFO: 'node/getNodeInfo',
    GETFULLNODEINFO: 'node/getFullNodeInfo',
    GETSELECTEDNODES: 'node/getSelectedNodes',
    GETSELECTEDNODEIDS: 'node/getSelectedNodeIds',
    TOGGLESELECTION: 'node/toggleSelection',
    DESELECTALLNODES: 'node/deselectAllNodes',
    MOVEMANYNODES: 'node/moveManyNodes',
    COPYMANYNODES: 'node/copyManyNodes',
    GETUSERS: 'node/getUsersForNodes',
    GETNODEVIEW: 'node/getNodeView',
    GETSUBNODES: 'node/getSubnodes',
    TOGGLENODEVIEW: 'node/toggleNodeView',
    SETNODEVIEW: 'node/setNodeView',
    UPDATENOTE: 'node/updateNote',
    UPDATEPROJECTSTATUS: 'node/updateProjectStatus',
    RENAMENOTE: 'node/renameNode',
    GETPROPERTIES: 'node/getProperties',
    NODECREATEDNOTIFICATION: 'node/nodeCreatedNotification',
    NODEUPDATEDNOTIFICATION: 'node/nodeUpdatedNotification',
    NODEDELETEDNOTIFICATION: 'node/nodeDeletedNotification',
    NODEMOVEDNOTIFICATION: 'node/nodeMovedNotification',
    NODECOPIEDNOTIFICATION: 'node/nodeCopiedNotification',
    UPDATENODEPROPERTY: 'node/updateNodeProperty',
    GETACCESSPOINTIDS: 'node/getAccessPointIds',
    SETISVIEWNODEMOVED: 'node/setViewNodeMoved',
    ISVIEWNODEMOVED: 'node/isViewNodeMoved',
    SETISVIEWNODEDELETED: 'node/setViewNodeDeleted',
    ISVIEWNODEDELETED: 'node/isViewNodeDeleted',
    ADDORUPDATENODES: 'node/addOrUpdateNodes',
  },
  ACCESSLINK: {
    GETACCESSLINKS: 'accesslink/getAccesslinks',
    CREATEACCESSLINK: 'accesslink/createAccesslink',
    UPDATEACCESSLINK: 'accesslink/updateAccesslink',
    DELETEACCESSLINK: 'accesslink/deleteAccesslink',
    INVITEACCESSLINK: 'accesslink/inviteAccesslink',
    REFRESHACCESSLINKS: 'accesslink/refreshAccesslinks',

    // External calls
    GETACCESSLINKINFO: 'accesslink/getAccesslinkInfo',
    AUTHORIZEACCESSLINK: 'accesslink/authorizeAccesslink',
    REFRESHACCESSLINKPODS: 'accesslink/refreshAccesslinkPods',
    GETACCESSLINKPODS: 'accesslink/getAccesslinkPods',
    GETCOMMENTS: 'accesslink/getComments',
    ADDCOMMENT: 'accesslink/addComment',
    UPDATECOMMENT: 'accesslink/updateComment',
    DELETECOMMENT: 'accesslink/deleteComment',
    DOWNLOADACCESSLINKFILES: 'accesslink/downloadAccesslinkFiles',
  },
  UPLOADLINK: {
    GETUPLOADLINKS: 'uploadlink/getUploadLinks',
    CREATEUPLOADLINK: 'uploadlink/createUploadLink',
    UPDATEUPLOADLINK: 'uploadlink/updateUploadLink',
    DELETEUPLOADLINK: 'uploadlink/deleteUploadLink',
    REFRESHUPLOADLINKS: 'uploadlink/refreshUploadLinks',
    GETUPLOADLINKINFO: 'uploadlink/getUploadlinkInfo',
    AUTHORIZEUPLOADLINK: 'uploadlink/authorizeUploadlink',
    REFRESHUPLOADLINKPOD: 'uploadlink/refreshUploadlinkInfo',
    CHECKSTORAGE: 'uploadlink/checkStorage',
    CREATEPOD: 'uploadlink/createPod',
    INVITEUPLOADLINK: 'uploadlink/inviteUploadlink',
    UPDATEUPLOADLINKS: 'uploadlink/updateUploadlinks',
    // GETVIEWPOD: 'uploadlink/getViewPod',
  },
  EMBEDMEDIA: {
    GETEMBEDLINKS: 'embedmedia/getEmbedLinks',
    CREATEEMBEDLINK: 'embedmedia/createEmbedLink',
    DELETEEMBEDLINK: 'embedmedia/deleteEmbedLink',
    REFRESHEMBEDLINKS: 'embedmedia/refreshEmbedLinks',
    GETEMBEDLINKINFO: 'embedmedia/getEmbedlinkInfo',
    REFRESHEMBEDLINKINFO: 'embedmedia/refreshEmbedlinkInfo',
  },
  SERVICE: {
    GETALLSERVICES: 'service/getAllServices',
    GETREKOGNITION: 'service/getRekognition',
    GETTEXTTOSPEECH: 'service/getTextToSpeech',
    GETTRANSLATIONS: 'service/getTranslations',
    GETEXTRACTFRAMES: 'service/getExtractFrames',
    GETMEDIACONVERTER: 'service/getMediaConverter',
    GETMEDIATRANSCODER: 'service/getMediaTranscoder',
    GETUPDATETHUMBNAIL: 'service/getUpdateThumbnail',
    GETIMPRINTSUBTITLES: 'service/getImprintSubtitles',
    GETGENERATINGSUBTITLES: 'service/getGeneratingSubtitles',
    GETARCHIVESERVICE: 'service/getArchiveService',
    GETEXPORTFCPXML: 'service/getExportFCPXML',
    GETUTILITYPODSERRORS: 'service/getUtilityPodsErrors',
    GETUTILITYNODESERRORS: 'service/getUtilityNodesErrors',
    GETUTILITYPODS: 'service/getUtilityPods',
    GETRUNNABLEPODS: 'service/getRunnablePods',
    GETRUNNABLENODES: 'service/getRunnableNodes',
    RESETUTILITYPODS: 'service/resetUtilityPods',
    UPDATEPODSUTILITY: 'service/updatePodsUtility',
    REFRESHSERVICES: 'service/refreshServices',
    REFRESHSERVICEOPTIONS: 'service/refreshServiceOptions',
    RUNSERVICE: 'service/runService',
    GETLIMITS: 'service/getLimits',
    REFRESHLIMITS: 'service/refreshLimits',
    RESETUTILITYNODES: 'service/resetUtilityNodes',
    ISPODRUNNABLE: 'service/isPodRunnable',
    GETUTILITYPOD: 'service/getUtilityPod',
  },
  USER: {
    REFRESHUSERS: 'user/refreshUsers',
    RESETUSERS: 'user/resetUsers',
    GETUSERS: 'user/getUsers',
    GETSELECTEDUSERS: 'user/getSelectedUsers',
    TOGGLESELECTION: 'user/toggleSelection',
    GETEDITUSER: 'user/getEditUser',
    SETEDITUSER: 'user/setEditUser',
    CREATEUSER: 'user/createUser',
    UPDATEUSER: 'user/updateUser',
    DELETEUSER: 'user/deleteUser',
    DETACHUSER: 'user/detachUser',
    ENABLEUSER: 'user/enableUser',
    SAVEPERMISSIONS: 'user/savePermissions',
    REFRESHUSERTYPES: 'user/refreshUsertypes',
    GETUSERTYPES: 'user/getUsertypes',
    GETDISABLEDUSERSTOGGLE: 'user/getDisabledUsersToggle',
    TOGGLEDISABLEDUSERS: 'user/toggleDisabledUsers',
    GETSORTEDBY: 'user/getSortedBy',
    CHANGEROOTNODE: 'user/changeRootNode',
    RESETSELECTEDUSERS: 'user/resetSelectedUsers',
    HIDEDISABLEDUSERS: 'user/hideDisabledUsers',
    ADDACCESSPOINT: 'user/addAccesspoint',
    DELETEACCESSPOINT: 'user/deleteAccesspoint',
    UPDATEACCESSPOINT: 'user/updateAccesspoint',
    PINGUSER: 'user/pingUser',
    UPDATEPASSWORD: 'user/updatePassword',
    SENDTMPPASSWORDCODE: 'user/sendTemporaryPasswordCode',
    RESETTEMPORARYPASSWORD: 'user/resetTemporaryPassword',
  },
  NOTIFICATION: {
    GETNOTIFICATIONS: 'notification/getNotifications',
    REFRESHNOTIFICATIONS: 'notification/refreshNotifications',
    SUBSCRIBE: 'notification/subscribe',
    UNSUBSCRIBE: 'notification/unsubscribe',
    NEWNOTIFICATION: 'notification/newNotification',
  },
  PLAYER: {
    RESETPLAYER: 'player/resetPlayer',
    ISPLAYING: 'player/isPlaying',
    ISSEEKING: 'player/isSeeking',
    SETISPLAYING: 'player/setIsPlaying',
    SETISSEEKING: 'player/setIsSeeking',
    DURATION: 'player/duration',
    SETDURATION: 'player/setDuration',
    VOLUME: 'player/volume',
    SETVOLUME: 'player/setVolume',
    MUTED: 'player/muted',
    SETMUTED: 'player/setMuted',
    SPEED: 'player/speed',
    SETSPEED: 'player/setSpeed',
    FULLSCREEN: 'player/fullscreen',
    SETFULLSCREEN: 'player/setFullscreen',

    CURRENTTIME: 'player/currentTime',
    SETCURRENTTIME: 'player/setCurrentTime',
    GETSEEKTIMEEVENT: 'player/getSeekTimeEvent',
    SETSEEKTIMEEVENT: 'player/setSeekTimeEvent',
    GETPAUSEEVENT: 'player/getPauseEvent',
    SETPAUSEEVENT: 'player/setPauseEvent',

    GETACTIVESUBTITLE: 'player/getActiveSubtitle',
    SETACTIVESUBTITLE: 'player/setActiveSubtitle',
    REFRESHSUBTITLES: 'player/refreshSubtitles',
    AVAILABLESUBTITLES: 'player/availableSubtitles',

    GETAVAILABLELAYERS: 'player/getAvailableLayers',
    GETACTIVELAYER: 'player/getActiveLayer',
    SETACTIVELAYER: 'player/setActiveLayer',
    GETLAYERSOPTIONS: 'player/getLayersOptions',
    SETLAYERSOPTIONS: 'player/setLayersOptions',
    REFRESHACTIVESUBTITLE: 'player/refreshActiveSubtitle'
  },
  SEARCH: {
    SEARCH: 'search/search',
    GETSEARCHZONE: 'search/getSearchZone',
    GETSEARCHQUERY: 'search/getSearchQuery',
    GETSEARCHRESULTS: 'search/getSearchResults',
    GETALLSEARCHZONES: 'search/getAllSearchZones',
    UPDATESEARCHRESULTPOD: 'search/updateSearchResultPod',
    SETSEARCHQUERY: 'search/setSearchQuery',
    SEARCHINFOLDERS: 'search/searchInFolders',
    GETFOLDERSEARCHRESULTS: 'search/getFolderSearchResults',
    RESETFOLDERSEARCHRESULTS: 'search/resetFolderSearchResults',
  },
  FILTER: {
    GETFILTEREDLIST: 'filter/getFilteredList',
    GETARCHIVEDFILTER: 'filter/getArchivedFilter',
    SETARCHIVEDFILTER: 'filter/setArchivedFilter',
    // GETAVAILABLENODETYPES: 'filter/getAvailableNodeTypes',
    // SETNODETYPEFILTER: 'filter/setNodeTypeFilter',
    GETAVAILABLEDATES: 'filter/getAvailableDates',
    SETDATESRANGEFILTER: 'filter/setDatesRangeFilter',
    GETAVAILABLEUPLOADERS: 'filter/getAvailableUploaders',
    SETUPLOADERSFILTER: 'filter/setUploadersFilter',
    GETAVAILABLEFILETYPES: 'filter/getAvailableFileTypes',
    SETFILETYPESFILTER: 'filter/setFiletypesFilter',
    GETAVAILABLEPODSTATUSES: 'filter/getAvailablePodStatuses',
    SETPODSTATUSFILTER: 'filter/setPodStatusFilter',

    SETSORTCOLUMN: 'filter/setSortColumn',
    GETSORTCOLUMN: 'filter/getSortColumn',
    GETSORTDESC: 'filter/getSortDesc',
  },
  USAGE: {
    GETUSERSDATA: 'usage/getUsersData',
    REFRESHUSERSDATA: 'usage/refreshUsersData',
    GETTENANTSDATA: 'usage/getTenantsData',
    REFRESHTENANTSDATA: 'usage/refreshTenantsData',
    GETUSERINFO: 'usage/getUserInfo',
    GETTENANTINFO: 'usage/getTenantInfo',
    REFRESHUSERINFO: 'usage/refreshUserInfo',
    REFRESHTENANTINFO: 'usage/refreshTenantInfo',
  },
  SYSTEM: {
    ONBOARDTENANT: 'system/onBoardTenant',
    GETTENANTEMAILS: 'system/getTenantEmails',
    SAVETENANTEMAILS: 'system/saveTenantEmails',
    VALIDATETENANTEMAIL: 'system/validateTenantEmail',
    MANAGESUBSCRIPTIONURL: 'system/manageSubscriptionUrl',
    UPDATETENANTMFA: 'system/updateTenantMFA',
    GETBRANDINGINFO: 'system/getBrandingInfo',
    SAVEBRANDINGINFO: 'system/saveBrandingInfo',
    DELETEBRANDLOGO: 'system/deleteBrandLogo',

    GETSUBSCRIPTION: 'system/getSubscription',
    REFRESHSUBSCRIPTION: 'system/refreshSubscription',
    SUBSCRIBEFORPLAN: 'system/subscribeForPlan',
    BUYADDONS: 'system/buyAddons',
  },
  ACCOUNT: {
    UPDATEFIRSTNAME: 'account/updateFirstName',
    UPDATELASTNAME: 'account/updateLastName',
    UPDATENOTIFICATIONSETTING: 'account/updateNotificationSetting',
    UPDATELANGUAGE: 'account/updateLanguage',
    UPDATEEMAILADDRESS: 'account/updateEmailAddress',
    CHANGETHEME: 'account/changeTheme',
    CHANGEFOLDERVIEW: 'account/changeFolderView',
    GETUSAGE: 'account/getUsage',
    GETACCOUNTDELETIONINFO: 'account/getAccountDeletionInfo',
    DELETEACCOUNT: 'account/deleteAccount',
  },
  SUBTITLE: {
    GETSUBTITLEASSETS: 'subtitle/getSubtitleAssets',
    SETSUBTITLES: 'subtitle/setSubtitles',
    GETSUBTITLES: 'subtitle/getSubtitles',
    SETSELECTEDASSET: 'subtitle/setSelectedAsset',
    GETSELECTEDASSET: 'subtitle/getSelectedAsset',
    RESETSELECTEDASSET: 'subtitle/resetSelectedAsset',
    SELECTSUBTITLEASSET: 'subtitle/selectSubtitleAsset',
    ADDSUBTITLELINE: 'subtitle/addSubtitleLine',
    UPDATESUBTITLELINE: 'subtitle/updateSubtitleLine',
    DELETESUBTITLELINE: 'subtitle/deleteSubtitleLine',
    REMOVESUBTITLELINE: 'subtitle/removeSubtitleLine',
    RESETSUBTITLES: 'subtitle/resetSubtitles',
    GETTRANSFORMEDSUBTITLES: 'subtitle/getTransformedSubtitles',
    RESETSTATE: 'subtitle/resetState',
    TOGGLESUBTITLEVIDEO: 'subtitle/toggleSubtitleVideo',
  },
  TENANT: {
    GETTENANTS: 'tenant/getTenants',
    REFRESHTENANTS: 'tenant/refreshTenants',
    FETCHALLTENANTS: 'tenant/fetchAllTenants',
    RESTORETENANT: 'tenant/restoreTenant',
    GETTENANTSETTINGS: 'tenant/getTenantSettings',
    UPDATETENANTSETTINGS: 'tenant/updateTenantSettings',
  },
  LABEL: {
    GETLABELS: 'label/getLabels',
    REFRESHLABELS: 'label/refreshLabels',
    CREATELABEL: 'label/createLabel',
    DELETELABEL: 'label/deleteLabel',
    UPDATELABEL: 'label/updateLabel',
    GETCATEGORIES: 'label/getCategories',
    REFRESHCATEGORIES: 'label/refreshCategories',
    CREATECATEGORY: 'label/createCategory',
    DELETECATEGORY: 'label/deleteCategory',
    UPDATECATEGORY: 'label/updateCategory',
    GETACTIVECATEGORYID: 'label/getActiveCategoryId',
    SETACTIVECATEGORYID: 'label/setActiveCategoryId',
    FETCHLABELS: 'label/fetchLabels',
    RESETLABELS: 'label/resetLabels',
    FINDLABLES: 'label/findLabels',
  },
  RECYCLEBIN: {
    GETALLNODES: 'recycleBin/getAllNodes',

    REFRESHNODESLIST: 'recycleBin/refreshNodesList',
    SETNODES: 'recycleBin/setNodes',
    TOTALPODS: 'recycleBin/totalPods',
    PERMANENTLYDELETEPODS: 'recycleBin/permanentlyDeletePods',
    REMOVEPODS: 'recycleBin/removePods',
    RESTOREPODS: 'recycleBin/restorePods',
    GETNODEBYID: 'recycleBin/getNodeById',
    GETCHILDNODES: 'recycleBin/getChildNodes',
    SETSELECTEDNODE: 'recycleBin/setSelectedNode',
    SELECTEDNODE: 'recycleBin/selectedNode',
    EMPTYRECYCLEBIN: 'recycleBin/emptyRecycleBin',
    GETRETENTIONPERIOD: 'recycleBin/getRetentionPeriod',
    SETRETENTIONPERIOD: 'recycleBin/setRetentionPeriod',
    GETISREFRESHINGNODES: 'recycleBin/getIsRefreshingNodes',
    UPDATERECYCLEBINPOD: 'recycleBin/updateRecycleBinPod',
  },
};
